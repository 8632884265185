import React, { Component } from 'react';
import { Map, GoogleApiWrapper ,InfoWindow, Marker} from 'google-maps-react';


const mapStyles = {
  width: '100%',
  height: '300px',
  position:"absolute",
  top:"-300px"

};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };
  
  onMarkerClick = (props, marker, e) =>
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
  
    onClose = props => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };
  
  
  
  render() {
    return (
      <Map  class="location_map"
      google={this.props.google}
      zoom={14}
      style={mapStyles}
      initialCenter={
        {
          lat: 59.955413,
          lng: 30.337844
        }
      }
    >
      <Marker
        onClick={this.onMarkerClick}
        name={'Prinsesse Maries Allé 11, st. th. 1908 Copenhagen'}
      />
      <InfoWindow
        marker={this.state.activeMarker}
        visible={this.state.showingInfoWindow}
        onClose={this.onClose}
      >
        <div>
          <h4>{this.state.selectedPlace.name}</h4>
        </div>
      </InfoWindow>
    </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDAhTrGan1VEa-ika_ZBZBskMfqNBnsXac'
})(MapContainer);