
import React from "react";
import Slider from "react-slick";


export default function SliderComponents(props) {
  var settings = {
    className: "imageSlider",
    centerMode: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <div className={"slider " + (props.class)}>
      <Slider {...settings}>

        <div>
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <img width="500px" height="300px" className="Rectangle57" alt="Vector" src="./Teraone/Rectangle 57 (1).png" />

              </div>
            </div>
          </div>
        </div>
        <div><div class="container">
          <div class="row">
            <div class="col-md-4">
              <img width="500px" height="300px" className="Rectangle58" alt="Vector" src="./Teraone/Rectangle 58.png" />

            </div>
          </div>
        </div>
        </div>
        <div><div class="container">
          <div class="row">
            <div class="col-md-4">
            <img width="500px" height="300px" className="Rectangle58" alt="Vector" src="./Teraone/Rectangle 58.png" />

            </div>
          </div>
        </div>
        </div>
        <div>
          <div class="container">
            <div class="row">
              <div class="col-md-4">
              <img width="500px" height="300px" className="Rectangle58" alt="Vector" src="./Teraone/Rectangle 58.png" />

              </div>

            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
