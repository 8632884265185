
import SliderComponents from "../../ImageCorousel";
import SimpleSlider from "../../ImageSlider";
import MultipleItems from "../../SliderComponents1";
import { HomeImage } from "../../TerrOne Images/Home/index"

export default function Home() {
    return (
        <div>

            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-sm-3">
                        <img className="Homerectangle1" alt="" src={HomeImage.rectangle1} />
                    </div>
                    <div class="col-sm-5">
                        <h1 className="HomeHeading1">Automate your<br /> construction projects with TerraOne Systems Integration.</h1>
                        <p className="HomePara">
                            From new construction to system upgrades,  TerraOne Systems Integration<br /> provides top-notch automation solutions. Whether
                            you need<br /> renovation or system maintenance, our team has got you covered.<br /> Say goodbye to manual processes and
                            hello to efficiency with<br />  TerraOne Systems Integration.
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <img className="Homerectangle2" alt="" src={HomeImage.rectangle2} />
                    </div>
                </div>
            </div>

            <div className="Homerectangle3">
                <div className="container text-center">
                    <h6 className="our-services">OUR SERVICES</h6>
                    <img className="vector6" alt="" src="./Teraone/Vector 6.png" />
                    <h6 className="leading-the-charge"> Leading the Charge Towards Global<br />
                        Green Future
                    </h6>
                </div>
                <MultipleItems className="multiSlider" />
                <a href="/Services" className="button2" >
                    <div className="explore-services">EXPLORE SERVICES</div>
                </a>
            </div>


            <div class="container-fluid">
                <div class="row align-items-start">
                    <div class="col m6">
                        <img className="rectangle38" alt="" src="./Teraone/Rectangle 38.jpg" />


                        <div class="row">
                            <div className="clients">40+<br />Clients</div>
                        </div>
                        <div class="row">
                            <div className="projects">200+<br />Project Done</div>

                        </div>
                        <div class="row">
                            <div className="countryServed">60+<br />Countries<br />Served</div>

                        </div>
                    </div>
                    <div class="col m6">
                        <h1 className="why-terraone">WHY TERRAONE</h1>
                        <div className="why-terraoneUnderline"></div>
                        <h1 className="Home-why-terraone">Your Trusted Partner for Seamless<br /> System Integration and Energy<br /> Excellence!</h1>
                        <p className="Home-why-terraonePara" >At TerraOne, we are dedicated to optimizing your energy management with precision. As experts in<br /> system integration, we tailor solutions to boost your business's potential, effectively managing electric,<br /> water, and natural gas usage while meeting your specific production, maintenance, and administrative<br /> needs. Our mission is to lead Ohio's Renewable Energy Management Consulting Services and pave the<br /> way towards a greener future</p>

                        <div class="container text-start">
                            <div class="row">
                                <div class="col-6 col-sm-5">

                                    <img className="HomeEllipse19" alt="" src="./Teraone/Ellipse 19.png" />
                                    <div className="HomeEnergyEfficient">Energy Efficient </div>
                                    <p className="HomeEnergyEfficientPara">We seamlessly integrate energy-efficient<br /> methods, ensuring your business operates at its<br /> greenest best.</p>

                                </div>
                                <div class="col-6 col-sm-5">

                                    <img className="HomeEllipse20" alt="" src="./Teraone/Ellipse 20.png" />
                                    <div className="Long-Term-Relationship">Long-Term Relationship</div>
                                    <p className="Long-Term-RelationshipDesc">Count on our unwavering support as your long-<br />term energy management partner.</p>


                                </div>

                                <div class="w-100"></div>

                                <div class="col-6 col-sm-5">

                                    <img className="HomeEllipse21" alt="" src="./Teraone/Ellipse 21.png" />
                                    <div className="Day-To-Day-Operations">Day To Day Operations </div>
                                    <p className="Day-To-Day-OperationsDesc">Our integration services bolster your company's<br /> day-to-day operations, ensuring seamless<br /> efficiency.</p>
                                </div>
                                <div class="col-6 col-sm-5">

                                    <img className="HomeEllipse22" alt="" src="./Teraone/Ellipse 22.png" />
                                    <div className="ReduceEnergyConsumption">Reduce Energy<br /> Consumption.</div>
                                    <p className="ReduceEnergyConsumptionDesc">Our comprehensive services empower<br /> organizations to make a positive impact on the<br /> environment and their bottom line.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="HomeSection4">
                <div className="text6">

                    <div className="Home-who-we-are">WHO WE ARE</div>
                    <img className="Homeicon7" alt="Vector" src="./Teraone/Vector 2.png" />
                    <button className="moreAboutUs">MORE ABOUT US</button>
                    <p className="Hometext7">Integration for a Sustainable Tomorrow: Empowering Harmony with Nature!</p>
                    <p className="Home-with-over-years">
                        With over 30 years of delivering the highest level of expertise in our field, ensuring the seamless integration
                        of cutting-edge technology and<br/> innovative engineering. With a commitment to excellence, we strive to exceed our
                        clients&#39; expectations by delivering superior automation<br/> solutions that optimize efficiency, increase
                        productivity, and enhance overall performance. Our experienced team of professionals is driven<br/> by a passion for
                        excellence, a dedication to customer satisfaction, and a relentless pursuit of technological advancement.
                        Together, we aim<br/> to establish TerraOne Engineering as a trusted partner in the realm of automation, setting new
                        standards of quality, reliability, and service in<br/> the industry.
                    </p>
                    <img className="rectangle11" alt="Rectangle" src="./Teraone/Rectangle 11.png" />
                    <img className="rectangle12" alt="Rectangle" src="./Teraone/Rectangle 12.png" />

                </div>
                </div>

            <div className="HomeSection5container">
                
                    <div className="Home-our-portfolio">OUR PORTFOLIO</div>
                    <img className="Homevector4" alt="Vector" src="./Teraone/Vector 4.png" />
                    <div className="HomeProjects">Projects</div>
                    <div className="button3">
                        <button className="viewMore">VIEW MORE</button>
                    </div>
                    <SimpleSlider class="portfolio" />
                


            </div>
            <div className="container-text-center5">
                <div className="OurPortfolio">Our portfolio</div>
                <div className="Vector3"></div>
                <div className="WhatsSayAbout" >Whats say about us our great clients</div>
                <SliderComponents className="clients" />
            </div>

            <div class="container" id="articleContainer">
                <div class="row">
                    <div class="col-md-5">
                        <div class="demo-content">
                            <div className="Articles">Articles</div>
                            <div className="Vector5"></div>
                            <div className="OurLatestBlogs" >Our Latest Blogs</div>
                            <div>
                                <img className="Rectangle98" alt="" src="/Teraone/Rectangle 98 (3).png" />
                            </div>
                            <div className="post">
                                <div className="February212023" >February 21, 2023 . Energy</div>
                                <div className="LoremIpsumDolorSitAmetConsectetur" >Lorem ipsum dolor sit amet consectetur.</div>
                                <div className="Pseudo"></div>
                                <div className="LoremI" >Lorem ipsum dolor sit amet consectetur. Sociis neque eget vitae nec ligula. Blandit cras cursus ac auctor proin quam in vel vitae. </div>

                            </div>
                            <div>
                                <button className="allArticles">ALL ARTICLES</button>
                            </div>
                        </div>
                    </div>
                    {/* <div class="container text-center">
                            <div class="row align-items-start">
                                <div class="col">
                                <img className="Rectangle99" alt=" " src="./Teraone/Rectangle 99 (3).png" />
                                </div>
                                <div class="col">
                                <div className="textContainer">
                                            <div className="February212023" >February 21, 2023 . Energy</div>
                                            <div className="LoremIpsumDolorSitAmetConsectetur" >Lorem ipsum dolor sit amet consectetur.</div>
                                            <div className="Pseudo"></div>
                                            <div className="LoremI" >Lorem ipsum dolor sit amet consectetur. Sociis neque eget vitae nec ligula. Blandit cras cursus ac auctor proin quam in vel vitae. </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div> */}
                    <div class="col-md-7">
                        <div class="demo-content bg-alt">
                            <div class="row">
                                <div class="col-6 col-sm-4">
                                    <img className="Rectangle99" alt=" " src="./Teraone/Rectangle 99 (3).png" /> </div>
                                <div class="col-6 col-sm-4">
                                    <div className="textContainer">
                                        <div className="February212023" >February 21, 2023 . Energy</div>
                                        <div className="LoremIpsumDolorSitAmetConsectetur" >Lorem ipsum dolor sit amet consectetur.</div>
                                        <div className="Pseudo"></div>
                                        <div className="LoremI" >Lorem ipsum dolor sit amet consectetur. Sociis neque eget vitae nec ligula. Blandit cras cursus ac auctor proin quam in vel vitae. </div>
                                    </div>
                                </div>

                                <div class="w-100 d-none d-md-block" id="ArticlesSpacing"></div>

                                <div class="col-6 col-sm-4"><img className="Rectangle99" alt=" " src="./Teraone/Rectangle 100.png" />
                                </div>
                                <div class="col-6 col-sm-4">
                                    <div className="textContainer">
                                        <div className="February212023" >February 21, 2023 . Energy</div>
                                        <div className="LoremIpsumDolorSitAmetConsectetur" >Lorem ipsum dolor sit amet consectetur.</div>
                                        <div className="Pseudo"></div>
                                        <div className="LoremI" >Lorem ipsum dolor sit amet consectetur. Sociis neque eget vitae nec ligula. Blandit cras cursus ac auctor proin quam in vel vitae. </div>
                                    </div>
                                </div>
                                <div class="w-100 d-none d-md-block" id="ArticlesSpacing"></div>

                                <div class="col-6 col-sm-4">
                                    <img className="Rectangle99" alt=" " src="./Teraone/Rectangle 101 (2).png" />
                                </div>
                                <div class="col-6 col-sm-4">
                                    <div className="textContainer">
                                        <div className="February212023" >February 21, 2023 . Energy</div>
                                        <div className="LoremIpsumDolorSitAmetConsectetur" >Lorem ipsum dolor sit amet consectetur.</div>
                                        <div className="Pseudo"></div>
                                        <div className="LoremI" >Lorem ipsum dolor sit amet consectetur. Sociis neque eget vitae nec ligula. Blandit cras cursus ac auctor proin quam in vel vitae. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-text-left7">
                <div className="GetAFreeConsultation">Get a Free Consultation<br />call 347-470-1812</div>
                <input type="name" id="yourName" name="name" placeholder="Your Name*"></input>
                <input type="email" id="emailAddress" name="email" placeholder="Your Email*"></input>
                <button className="Submit1" >submit</button>
            </div>












            {/* <Footer /> */}

        </div>
    )
}

