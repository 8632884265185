

export default function Portfolio() {
    return (
        <>

            <div>
                <body>
                    <img className="portfolioBanner" alt="" src="/Teraone/portfoliobanner.png" />
                    <h1 className="portfolioBannerHeading">Portfolio</h1>
                    <p className="portfolioBannerPara">Lorem ipsum dolor sit amet consectetur. Tristique tellus odio<br /> ullamcorper semper sed aliquam.</p>
                    <div class="container text-center">
                        <div class="row align-items-center">
                            <div class="col">
                                <h2 className="Our_work">Our work</h2>
                                <svg className="Our_work_underline" width="74" height="2" viewBox="0 0 74 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector 7" d="M0 1H74" stroke="black" />
                                </svg>
                                {/* <div className="Our_work_underline"></div> */}
                                <h1 className="Explore_our_work">Explore our work</h1>

                            </div>
                        </div>
                    </div>


                    <div class="container" id="poertfolioSection1container">

                        <div class="row">
                            <div class="col">
                                <img className="portfolioImage1" alt="" src="/Teraone/portfolioImage1.jpg" />

                            </div>
                            <div class="col-5">
                                <img className="portfolioImage2" alt="" src="/Teraone/portfolio1image5.png" />

                            </div>
                            <div class="col">
                                <div className="poertfolioImage3"></div>
                            </div>
                        </div>
                    </div>
                    <div class="container">

                        <div class="row">
                            <div class="col">
                                <h1 className="United_States_NAVY">United States NAVY</h1>
                                <p className="United_States_NAVY_para">The U.S. Navy was able to save 25% on<br /> their electrical consumption based upon<br /> the needs of the staff entering and working<br /> in of their building.</p>
                            </div>
                            <div class="col-5">
                                <img className="portfolioImage4" alt="" src="/Teraone/portfolioImage4.jpg" />

                            </div>
                            <div class="col">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="container text-start" id="poertfolioSection2container">
                        <div class="row align-items-start">
                            <div class="col">
                                <img className="portfolioimage5" alt="" src="/Teraone/portfolioimage5.png" />

                            </div>
                            <div class="col-6">
                                <img className="portfolioImage6" alt="" src="/Teraone/portfolioImage6.png" />

                                <h1 className="Eli_lilly_Company">Eli Lilly & Company</h1>
                                <p className="Eli_lilly_Company_para">By upgrading from pneumatic controls to direct digital<br /> controls (DDC), we demonstrated to Eli Lilly & Company<br /> that newer technology can accomplish the same building<br /> temperature, humidity, and pressure requirements and<br /> consume approximately 25% less energy than before.</p>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>
                    <div class="container text-center">
  <div class="row align-items-start">
   
    <div class="col"> 
     <img className="portfolioImage8" alt="" src="/Teraone/portfolioImage8.png" />
     <div className="portfolioImage7"></div>
                  
    </div>
  </div>
</div>
                  
                    <div class="container" id="poertfolioSection3container">
                        <div class="row align-items-start">
                            <div class="col">
                                <img className="portfolioImage9" alt="" src="/Teraone/portfolioImage9.png" />

                            </div>
                            <div class="col">
                                <h1 className="General_Mills">General Mills</h1>
                                <p className="General_Mills_para">General Mills had an energy reduction initiative of 15%.<br /> Our control schemes that interacted with the production<br /> allowed them to achieve their goal and more. One campus<br /> had a designed 20% electrical reduction just by adding one<br /> differential pressure sensor and controlling a pump based<br /> on that sensor.

                                </p>
                                <img className="portfolioImage10" alt="" src="/Teraone/portfolioImage10.png" />

                            </div>
                        </div>
                    </div>
                    <div class="container" id="poertfolioSection4container">
                        <div class="row align-items-start">
                            <div class="col">
                                <h1 className="United_Airlines">United Airlines</h1>
                                <p className="United_Airlines_para">United Airlines has always been very aggressive in not<br /> wasting energy. When they opened up their Indianapolis<br /> Maintenance Center in 1994, we were given the challenge<br /> of reducing their energy consumption by 20% with a six-<br />month ROI on any products purchased. With a brand new<br /> state-of-the-art facility, we were able to reduce their<br /> electrical consumption by 18% in just six months. We kept<br /> striving until we reached that goal of 20%.</p>

                            </div>
                            <div class="col">
                                <img className="portfolioImage11" alt="" src="/Teraone/portfolioImage11.png" />

                            </div>
                        </div>
                    </div>
                    <div class="container" id="poertfolioSection5container">
                        <div class="row align-items-start">
                            <div class="col">
                                <img className="portfolioImage12" alt="" src="/Teraone/portfolioImage12.png" />

                            </div>
                            <div class="col align-items-center">
                                <img className="portfolioImage13" alt="" src="/Teraone/portfolioImage13.png" />

                            </div>
                        </div>
                    </div>
                    <div class="container" id="poertfolioSection6container">
                        <div class="row align-items-start">
                            <div class="col">
                                <img className="portfolioImage14" alt="" src="/Teraone/portfolioImage14.png" />
                                <h1 className="Indiana_University">Indiana University–Purdue University<br /> Indianpolis</h1>
                                <p className="Indiana_University_para">United Airlines has always been very aggressive in not wasting energy.<br /> When they opened up their Indianapolis Maintenance Center in 1994, we<br /> were given the challenge of reducing their energy consumption by 20%<br /> with a six-month ROI on any products purchased. With a brand new state-<br />of-the-art facility, we were able to reduce their electrical consumption by<br /> 18% in just six months. We kept striving until we reached that goal of 20%.</p>

                            </div>
                            <div class="col">
                                <img className="portfolioImage15" alt="" src="/Teraone/portfolioImage15.png" />

                            </div>
                        </div>
                    </div>


                </body>
            </div>


            {/* <Footer/> */}

        </>


    )
}

