import './App.css';
import './Font/font.css';
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Footer from './components/Footer';
import About from './components/About/About';
import { BrowserRouter as Switch, Route,Routes  } from 'react-router-dom';
import Services from './components/Services/Services';
import Blogs from './components/Blogs/Blogs';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';



function App() {
  return (
       
    <>
      <Switch basename='/'>
      <Navbar/> 
      <Routes>
        <Route exact path='/Home' element ={<Home/>}/>
        <Route exact path='/About' element ={<About/>}/>
        <Route exact path='/Services' element ={<Services/>}/>
        <Route exact path='/Contact' element ={<Contact/>}/>
        <Route exact path='/Portfolio' element ={<Portfolio/>}/>
        <Route exact path='/Blogs' element ={<Blogs/>}/>
      
      </Routes>
      <Footer/>
      </Switch> 
    </>
  );
}

export default App;
