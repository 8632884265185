import { BlogImages } from "../../TerrOne Images/Blogs/BlogsImages"

export const blogData = [
    {
        img: BlogImages.blogsFooterimage1,
        date: "Jan 25 2022",
        title: "Energy",
        heading: "4 best questions to ask your building energy consultant",
        description: "Description: In this article, we will look, at which questions you should ask your building energy consultant. From general stores to big factories and buildings, companies are effectively searching for ways of saving energy to be more productive and have more command over their costs.",

    },
    {
        img: BlogImages.blogsFooterimage2,
        date: "Jan 25 2022",
        title: "Energy",
        heading: "Why should we hire an energy consulting company? top 4 benefits and advantages.",
        description: "Description: In this article, you will get to know why we should hire an energy consultancy company? We will also focus on the benefits and advantages of hiring an energy consulting company.",
    },
    {
        img: BlogImages.blogsFooterimage3,
        date: "Jan 25 2022",
        title: "Energy",
        heading: "How would a company benefit from an energy management programme? Top 5 Energy Management Programme Benefit.",
        description: "Description: In this article, you will get to know why we should hire an energy consultancy company? We will also focus on the benefits and advantages of hiring an energy consulting company.",
    },
    {
        img: BlogImages.blogsFooterimage4,
        date: "Jan 25 2022",
        title: "Energy",
        heading: "What is building management system? BMS",
        description: "Description: In this article, we will look, at which questions you should ask your building energy consultant. From general stores to big factories and buildings, companies are effectively searching for ways of saving energy to be more productive and have more command over their costs.",
    }
]
