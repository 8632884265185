import MultipleRows from "../../AboutSlider";





export default function About() {
    return (
        <div>

            <div >
                <img className="bannerImage" alt="" src="Teraone/Rectangle 105 (1).png" />
                <h1 className="bannerHeading">About us</h1>
                <p className="bannerPara">Terraone is committed to helping businesses optimize their<br /> processes and achieve maximum efficiency.</p>
            </div>


            <div class="container text-center" id="aboutContainer">
  <div class="row align-items-start">
    <div class="col-5">
    <div className="Rectangle106">
                <p className="aboutPara">ABOUT</p>
                <div className="aboutUnder"></div>
                <div className="aboutDesc">Welcome to<br />our System<br />Integration<br />Company </div>
                <img className="Rectangle107" alt="" src="Teraone/Rectangle 107.png" />

                </div>
    </div>
    <div class="col">
    <div>
                <div className="descriptionIntegration">Welcome to our system integration<br />company</div>
                <div className="descriptionTerra">TerraOne Engineering has built a reputation for delivering exceptional results. We <br />understand the challenges faced by businesses in today's fast-paced world, and we<br /> are here to provide innovative solutions that streamline operations and drive<br /> productivity.<br />
                    Our team of skilled professionals is equipped with the knowledge and expertise to<br /> design, implement, and maintain automation systems tailored to the unique needs of<br /> each client. Whether you require a small-scale upgrade or a comprehensive system<br /> overhaul, our experts have the technical know-how to deliver outstanding results.</div>
            </div>
    </div>
  </div>
</div>

            <div>
                
                
            </div>
            

            
            <div>
                <div className="Rectangle42" ></div>
                <div className="Our-Mission">Our Mission</div>
                <div className="Our-MissionDescp">Terraone works with the vision of delivering solutions that are reliable, efficient,<br /> and cost-effective. Our team meticulously plans and executes each project, paying<br /> attention to every detail to ensure seamless integration and optimal performance.<br /> Downtime can hurt businesses, so we focus on sticking to schedules. Our team<br /> works efficiently to minimize disruptions and ensure a smooth transition to your<br /> new system.
                </div>
                <MultipleRows />
            </div>

            <div className="">
                <div className="ourWorkflow" >our workflow</div>
                <div className="ourWorkflowUnder"></div>
                <div className="fromStrategy">From Strategy to Implementation</div>
            </div>
            <div>
                <img className="image3" alt="" src="Teraone/image 3.svg" />
                <div className="consultation">Consultation</div>
                <div className="consultationDesc">We understand that every project<br /> is unique, and your vision is the<br /> cornerstone of our work. Our<br /> experts sit down with you to listen,<br /> learn, and discuss your specific<br /> needs and aspirations.</div>
            </div>
            <div>
                <img className="image4" alt="" src="Teraone/image 4.svg" />
                <div className="design">Design</div>
                <div className="designDesc">From selecting the right<br /> technology to optimizing energy<br /> efficiency, our design process is a<br /> harmonious blend of innovation<br /> and practicality. The result? A<br /> blueprint that will set the stage for<br /> seamless automation.</div>
            </div>
            <img className="image5" alt="" src="Teraone/image 5.svg" />
            <div className="installation">Installation</div>
            <div className="installationDesc">With precision and efficiency, we<br /> transform your vision into a fully<br /> functional automation system.<br /> Our commitment to quality shines<br /> through every wire, every<br /> component, and every<br /> connection. The installation<br /> process is a true testament to our<br /> dedication to excellence.</div>

            <img className="image6" alt="" src="Teraone/image 6.svg" />
            <div className="ongoingSupport">Ongoing Support</div>
            <div className="ongoingSupportDesc">At TerraOne Engineering, we don't<br /> simply complete a project and walk<br /> away. Our team is just a call away,<br /> ready to troubleshoot, upgrade,<br /> and maintain your automation<br /> system. We're committed to the<br /> long-term success of your project,<br /> ensuring that efficiency and<br /> convenience remain at the<br /> forefront.</div>


            {/* <div class="container-image">
  <div class="row">
    <div class="col-sm">
    </div>
    <div class="col-sm">
        
    </div>
    <div class="col-sm">
    </div>
    <div class="col-sm">
    </div>
  </div>
</div> */}
            {/* <div> */}
            <div className="container-fluid" id="rectDwayne">
                <div className="Meetthefounder">Meet the founder</div>
                <div className="MeetthefounderUnder"></div>
                <div className="founderDedication">A Leader Dedicated To Energy Management</div>
                <div className="founderDedicationDesc">Dwayne’s work has helped clients save energy in markets from aviation to<br /> pharmaceutical to food and beverage to education and to our military.</div>
                <img className="dwaynePhoto" alt="" src="Teraone/Dwayne.png" />
                <div className="founderName">DWAYNE PURVIS</div>
                <div className="founderPost">Founder & CEO</div>

            </div>
            <div className="rectDwayne1">

            </div>



        </div>


    )
}
