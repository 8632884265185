import React, { Component } from "react";
import Slider from "react-slick";

export default class MultipleRows extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      className: "aboutSlider",
      centerMode: true,
      arrows: false,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 2,
    };
    return (
      <div className="aboutSlider">
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          <div></div>
          <div>
            <img
              className="Rectangle109"
              alt=""
              src="Teraone/Rectangle 109.png"
            />
          </div>
          <div>
            <img
              className="Rectangle108"
              alt=""
              src="Teraone\Rectangle 108.png"
            />
          </div>
          <div></div>

          <div></div>
          <div>
            <img
              className="Rectangle109"
              alt=""
              src="Teraone/Rectangle 109.png"
            />
          </div>
          <div>
            <img
              className="Rectangle108"
              alt=""
              src="Teraone\Rectangle 108.png"
            />
          </div>
          <div></div>
          <div></div>
          <div>
            <img
              className="Rectangle109"
              alt=""
              src="Teraone/Rectangle 109.png"
            />
          </div>
          <div>
            <img
              className="Rectangle108"
              alt=""
              src="Teraone\Rectangle 108.png"
            />
          </div>
          <div></div>
        </Slider>
        <div style={{ textAlign: "center" }}>
          <button className="buttonSlickPrev" onClick={this.previous}>
            <svg
              width="47"
              height="8"
              viewBox="0 0 47 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Arrow 2"
                opacity="0.6"
                d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM47 3.5L1 3.5V4.5L47 4.5V3.5Z"
                fill="white"
              />
            </svg>
          </button>
          <button className="buttonSlickNext" onClick={this.next}>
            <svg
              width="47"
              height="8"
              viewBox="0 0 47 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Arrow 3"
                d="M46.3536 3.64645C46.5488 3.84171 46.5488 4.15829 46.3536 4.35355L43.1716 7.53553C42.9763 7.7308 42.6597 7.7308 42.4645 7.53553C42.2692 7.34027 42.2692 7.02369 42.4645 6.82843L45.2929 4L42.4645 1.17157C42.2692 0.976311 42.2692 0.659728 42.4645 0.464466C42.6597 0.269204 42.9763 0.269204 43.1716 0.464466L46.3536 3.64645ZM0 3.5L46 3.5V4.5L0 4.5L0 3.5Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
}
