import blogBanner from "./blogsBanner.png";
import blogBanner2 from "./blogBanner2.png";
import bloglatestpostimage1 from "./bloglatestpostimage1.png";
import bloglatestpostimage2 from "./bloglatestpostimage2.png";
import bloglatestpostimage3 from "./bloglatestpostimage3.png";
import blogsFooterimage1 from "./blogsFooterimage1.png";
import blogsFooterimage2 from "./blogsfooterimage2.png";
import blogsFooterimage3 from "./blogsfooterimage3.png";
import blogsFooterimage4 from "./blogsfooterimage4.png";

export const BlogImages
 = {
    blogBanner,
    blogBanner2,
    bloglatestpostimage1,
    bloglatestpostimage2,
    bloglatestpostimage3,
    blogsFooterimage1,
    blogsFooterimage2,
    blogsFooterimage3,
    blogsFooterimage4,

}