import contactBanner from "./contactBanner.png";

import contactBanner2 from "./contactBanner2.png";
import contactImage1 from "./Ellipse 2.svg";
import contactImage2 from "./Ellipse 3.svg";


export const ContactImage
 = {
    contactBanner,
    contactBanner2,
    contactImage1,
    contactImage2
    
}