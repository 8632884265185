import rectangle1 from "./Rectangle 6.png";
import rectangle2 from "./Rectangle 5.png";


import rectangle38 from "./Rectangle 38.jpg"

export const HomeImage = {
    rectangle1,
    rectangle2,
    rectangle38
}