import React from 'react';


export default function Footer() {
    return (
        <div>
        <div className="container-text-center" id='FooterContactUs'>
            <div className="Contact" >CONTACT</div>
            <div className="ContactUs" >Contact Us</div>
            <form>
                <div className="Name">Name</div><br />
                <input type="name" id="name" name="name" placeholder="Enter Full Name" /><br />
                <div className="PhoneNumber">Phone Number</div><br />
                <input type="phoneNumber" id="phone" name="phone" placeholder="Enter Phone Number" /><br />
                <div className="EmailAddress">Email Address</div><br />
                <input type="email" id="email" name="email" placeholder="Enter Your Email Address" /><br />
                <div className="Message">Message</div><br />
                <input id="message" rows="4" cols="50" placeholder="How can we help you?"></input><br />
                <button className="submit" type="submit">Submit</button>
            </form>
            </div>
        <div className="footerContainer">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className='schedule'>Schedule a free consultation</div>
                            <div className='footerBut'>
                                <div className='footerButton'>How Can We Help You?</div>
                                <div className="ShareYourGoals" >Share your goals, thoughts, and ideas, and we will explore concepts, design, and build great online experiences making customers and users love and share your brand.</div>

                            </div>
                        </div>
                        <div className="col">
                            <div className="prinsesseMaries" >Phone no.</div>
                            <div className="mobile" >347-470-1812</div>
                            <div className="findUs" >Find us</div>
                            <div className="Copenhagen" >Prinsesse Maries Allé 11, st. th. 1908 Copenhagen</div>
                            <div className="Email" >Email</div>
                            <div className="InfoTerraoneEngineering" >info@terraone.engineering</div>

                        </div>
                        <div className="col">
                            <div className="ServicesAboutPortfolioBlogsContact">Services<br />About<br />Portfolio<br />Blogs<br />Contact</div>

                        </div>
                        <div className="col">
                            <div className="WhatsappFacebookLinkedinTelegram" >Whatsapp<br />Facebook<br />LinkedIn<br />Telegram</div>


                        </div>
                    </div>
                </div>
                <div className="Line68"></div>
            <div className="AllRightReservedCopyright2023PrivacyPolicy">ALL RIGHT RESERVED. Copyright@2023.          Privacy Policy</div>
            <div className="DesignedByTechqilla1">Designed By</div>
            <div className="DesignedByTechqilla2">Techqilla</div>
            <div />
            </div>

                    
            </div>

    )
}


