// import MinimizeMaximizeContent from "../Minimize and Maximize components/Minimizeandmaximize";
import { BlogImages } from "../../TerrOne Images/Blogs/BlogsImages";
import { useState } from "react";
import { blogData } from "./Data";

export default function Blogs() {
    const [blogsData, setBlogData] = useState(blogData[0]);

    return (


        <div>
            <img className="blogsBanner" alt="" src={BlogImages.blogBanner} />
            <h1 className="blogsBannerHeading">Our Blogs</h1>
            <p className="blogsBannerPara">Lorem ipsum dolor sit amet consectetur. Tristique tellus odio<br /> ullamcorper semper sed aliquam.</p>

            <div class="container text-center">
                <div class="row align-items-start">
                    <div class="col-12 col-lg-8">
                        <h1 className="Types_of_energy_management">Types of energy management</h1>

                        <p className="Types_of_energy_management_para">JAN 25 2022</p>

                        <img className="blogsBanner2" alt="" src={BlogImages.blogBanner2} />

                        <div>
                            <h1 className="energy_management_in_agriculture">1. Energy management in agriculture</h1>
                            <p className="energy_management_in_agriculture_para">
                                New technologies and practices for on-farm energy management is improving the producers economic and enable<br /> environmental sustainability in agriculture. When seeing energy use, you ought to add energy bills, submeters, and<br /> assessments.<br />
                                <ul><li> SUBMETER </li></ul>
                                A submeter is an electricity or we can say a gas meter that is completely installed downstream of the main utility meter.<br /> They can be utilized to distinguish the energy use of a particular region. Submeters are valuable for gathering energy use<br /> design data.They can be utilized to follow utilization as detailed as a load-by-load basis. Submeters are accessible for<br /> power, petroleum gas, water, and fuel.<br />
                                <ul><li>ENERGY BILLS</li></ul>
                                Monitoring your month -to- month energy utilization expenses can give a gauge of the expense of production and identify<br /> trends or problems.
                                <ul><li>ENERGY EFFICIENCY</li></ul>
                                Energy productivity is accomplishing a similar work with less energy. An obvious way to improve efficiency is to replace<br /> older powered equipment with higher efficiency equipment at the end of its service life. Occasionally it makes economic<br /> sense to replace equipment sooner depending on the sort of hardware, cost of fuel, day by day and yearly run-times, and<br /> the degree of efficiency.<br />
                                Important areas for energy efficiency include:
                                <ol>
                                    <li>lighting</li>
                                    <li>heating</li>
                                    <li>refrigeration</li>
                                    <li>waterers</li>
                                    <li>variable frequency drives (especially for irrigation)</li>
                                    <li>controllers</li>
                                    <li>grain dryers</li>
                                    <li>combined heat and power</li>
                                </ol>
                                <ul><li>RENEWABLE ENERGY</li></ul>
                                Renewable energy technologies convert renewable resources, which can be fill up indefinitely, into electric or heat energy<br /> with diminished carbon emissions contrasted with traditional energy (conventional energy) sources<br />
                                Wind power, sunlight based photovoltaics, and limited scope hydroelectric generation have zero greenhouse gas emissions<br /> from generation. Solar photovoltaics is generally accessible and available at residential and farm scales Costs have<br /> dropped for even limited scope renewable energy and can give a good Levelized cost of energy when contrasted with grid-<br />purchased electricity and help with reliability issues.
                            </p>
                        </div>


                        <h1 className="Energy_management_in_smart_Factory">2. Energy management in smart Factory</h1>
                        <p className="Energy_management_in_smart_Factory_para">
                            <ul><li>Water reuse</li></ul>
                            Reusing water in the industry has the potential to reduce the costs of water supply and wastewater treatment by industries<br /> and reduce pressure on water resources. wastewater can be reused inside a business itself, or between a few organizations<br /> through modern advantageous interaction. Contingent upon the kind and nature of the wastewater, it might either be<br /> reused directly or treated before reuse or before it is recycled.<br />
                            Instances of direct reuse of wastewater in modern beneficial interaction include<br />
                            Reuse of organic waste or wastewater for large scale biogas production through anaerobic digestion which can then be<br />
                            utilized to create electricity.
                            <ul><li>Wind trees</li></ul>
                            Seems much interesting, the urban ‘wind trees’ generate electricity from breezes. the wind tree uses tiny blades into the<br /> aero leaves to generate power from the wind energy. Simply, at the point when the breeze blows the leaf turbines rotate<br /> and produce the energy.

                        </p>
                        <h1 className="Energy_Management_in_buildings">3. Energy management in buildings</h1>
                        <p className="Energy_Management_in_buildings_para">
                            <ul><li>Energy management in the building is a computer-based system that monitors and controls building's electrical and<br /> mechanical equipment like lighting, power systems, heating, and ventilation. Links interface different series of hubs<br /> around the structure to a central administrative. computer where building administrators can handle the structure. The<br /> building energy management gives control capacities, observing, and cautions, and permits the administrators to<br /> upgrade building performance.</li>
                                <li>Development in innovative headway has made building energy management the executives frameworks a fundamental<br /> part for overseeing energy interest, particularly in huge building locales. They can proficiently control 84% of your<br /> structure energy utilization</li></ul>
                        </p>
                        <h1 className="Energy_management_in_homes">4. Energy management in homes</h1>
                        <p className="Energy_management_in_homes_para">
                            <ul><li>Electricity</li>
                                <li>The functionality of energy management in homes involves the use of electricity within the home. The key<br /> consideration n electricity use management will include grid electricity rates. regardless of whether the client is on<br /> schedule of utilization charging, and whether there is solar power or batteries accessible on location.</li>
                                <li>Solar PV</li>
                                <li>A solar photovoltaic (PV) power – in the form of solar panels has become commonplace in many countries, allowing<br /> homes to generate a portion of their electricity on-site. a solar system owner might focus on it to 'self-consume' their<br /> solar energy directly or to export it to the grid as much as reasonably be expected.</li>
                                <li>Battery storage</li>
                                <li>To lower the electricity bills and to greater, home energy self-sufficiency battery storage has become the next frontier in<br /> home energy management. It is an intelligent energy management system whether you're on a time of use of flat-rate<br /> tariffs and whether you have any incentive to sell stored energy into the grid.</li>
                                <li>Solar thermal</li>
                                <li>Solar thermal or we can say solar hot water is the most popular technology that uses the sun's power to heat water<br /> within a home. Solar hot water systems often operate mostly independently of the rest of a home's electrical devices.</li>
                                <li>So now, we hope that the whole concept of the types of the energy management system is clear to you with deep<br /> knowledge. for all the more such informative blogs stay tuned.</li></ul>
                        </p>
                        <div className="blogsRelatedTags">Related tags :</div>
                        <h1 className="Energy_fossil_fuels">Energy, Fossil fuels</h1>
                        <blogData data={blogData} />
                        <div class="container text-start">

                            <div class="row align-items-start">
                                {blogData.map(data => {
                                    return (
                                        <>
                                            <div class="col-12 col-md-5">

                                                <img className="blogsFooterimage1" alt="" src={data.img} />
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <p className="blogsFooterimage1Date">{data.date}</p>
                                                <p className="blogsFooterimage1Energy">{data.title}</p>

                                                <h2 className="blogsFooterimage1heading">{data.heading}</h2>
                                                <p className="blogsFooterimage1para">{data.description}</p>
                                                {/* <div className="blogsFooterimage1readmore" >READ MORE</div>
                                                <svg className="blogsFooterimage1underline" width="98" height="2" viewBox="0 0 78 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path id="Vector 9" d="M0 1H78" stroke="#174689" />
                                                </svg> */}
                                                <button onClick={() => setBlogData(data)}>Read more</button>
                                                {/* <MinimizeMaximizeContent/> */}
                                            </div>
                                        </>
                                    )
                                })
                                }
                                <div className="footerextra"></div>
                            </div>
                        </div>



                        {/*//// Blog page Right section ////////*/}


                    </div>
                    <div class="col">
                        <div className="emailSubscription">
                            <h1 className="subscribe_to_our_newsletter">subscribe to our newsletter</h1>
                            <p className="subscribe_to_our_newsletter_para"></p>

                            <form>
                                <input type="email" id="blogsEmail" name="email" placeholder="Your Email" /><br />
                                <button className="blogsSubscribe" type="Subscribe">Subscribe</button>
                            </form>
                        </div>
                        <div className="socialSection">
                            <h1 className="follow_us_on">follow us on</h1>
                            <p className="follow_us_on_para"></p>
                            <svg className="blogsWhatsapp" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="843786_whatsapp_icon">
                                    <g id="Group">
                                        <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M17.1799 3.96863C15.457 2.24378 13.1659 1.29348 10.7248 1.29248C5.69509 1.29248 1.60144 5.3858 1.59948 10.4171C1.59877 12.0254 2.01897 13.5952 2.81755 14.9791L1.52295 19.7077L6.3604 18.4387C7.69323 19.1657 9.1939 19.5489 10.7212 19.5494H10.7249C10.7246 19.5494 10.7252 19.5494 10.7249 19.5494C15.754 19.5494 19.848 15.4557 19.8501 10.4243C19.851 7.98605 18.9027 5.69339 17.1799 3.96863ZM10.7248 18.0083H10.7218C9.36084 18.0078 8.02597 17.6421 6.86146 16.9511L6.58454 16.7868L3.71392 17.5397L4.48017 14.741L4.29981 14.454C3.54061 13.2464 3.13963 11.8507 3.14022 10.4176C3.14185 6.23579 6.54435 2.83367 10.7279 2.83367C12.7537 2.8343 14.6581 3.62429 16.09 5.05791C17.522 6.49153 18.3102 8.39714 18.3094 10.4237C18.3077 14.6058 14.9053 18.0083 10.7248 18.0083Z" fill="#174689" />
                                        <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M14.8849 12.328C14.6569 12.2138 13.5359 11.6623 13.3269 11.5862C13.1179 11.5101 12.9659 11.472 12.8139 11.7003C12.662 11.9285 12.225 12.4421 12.092 12.5943C11.959 12.7464 11.8259 12.7656 11.598 12.6513C11.37 12.5372 10.6353 12.2965 9.76442 11.5197C9.08656 10.915 8.62898 10.1684 8.49593 9.9401C8.36295 9.71189 8.48178 9.58851 8.59594 9.47479C8.69851 9.37259 8.82397 9.2085 8.93798 9.07533C9.05196 8.94224 9.08996 8.84704 9.16597 8.69499C9.24198 8.54278 9.20402 8.40962 9.14697 8.29553C9.08996 8.18141 8.63398 7.05912 8.444 6.60251C8.25886 6.15797 8.07091 6.2182 7.93093 6.21113C7.79814 6.2045 7.64593 6.20312 7.49395 6.20312C7.34197 6.20312 7.0949 6.26021 6.88595 6.48842C6.67696 6.71671 6.08789 7.26837 6.08789 8.39054C6.08789 9.51287 6.90492 10.5971 7.01893 10.7492C7.13291 10.9014 8.62672 13.2044 10.914 14.192C11.458 14.427 11.8827 14.5673 12.2138 14.6723C12.76 14.8459 13.2571 14.8214 13.6499 14.7627C14.088 14.6972 14.999 14.2111 15.1889 13.6786C15.379 13.1459 15.379 12.6893 15.3219 12.5943C15.2649 12.4992 15.1129 12.4421 14.8849 12.328Z" fill="#174689" />
                                    </g>
                                </g>
                            </svg>
                            <svg className="blogsFacebook" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="104498_facebook_icon">
                                    <path id="Vector" d="M14.9757 8.05248H12.1439V6.19521C12.1439 5.49772 12.6062 5.3351 12.9318 5.3351C13.2566 5.3351 14.9302 5.3351 14.9302 5.3351V2.2688L12.178 2.25806C9.1228 2.25806 8.42753 4.54501 8.42753 6.00852V8.05248H6.66064V11.2121H8.42753C8.42753 15.2671 8.42753 20.1529 8.42753 20.1529H12.1439C12.1439 20.1529 12.1439 15.2189 12.1439 11.2121H14.6516L14.9757 8.05248Z" fill="#174689" />
                                </g>
                            </svg>
                            <svg className="blogsLinkedIn" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="367593_linkedin_business_network_social_icon">
                                    <path id="Vector" d="M2.10517 7.62283H5.79703V18.7328H2.10517V7.62283ZM3.97581 2.26733C2.71224 2.26733 1.88672 3.09688 1.88672 4.18568C1.88672 5.25208 2.68808 6.10574 3.92749 6.10574H3.95104C5.23876 6.10574 6.0407 5.25204 6.0407 4.18568C6.01654 3.09688 5.2388 2.26733 3.97581 2.26733ZM14.8616 7.36184C12.9019 7.36184 12.0239 8.43973 11.5342 9.19565V7.62283H7.8412C7.89009 8.66507 7.8412 18.7328 7.8412 18.7328H11.5342V12.5282C11.5342 12.1959 11.5583 11.8648 11.6555 11.6268C11.9229 10.9634 12.5305 10.2764 13.5497 10.2764C14.8869 10.2764 15.4209 11.2957 15.4209 12.7885V18.7328H19.1133V12.3621C19.1133 8.94964 17.2921 7.36184 14.8616 7.36184Z" fill="#174689" />
                                </g>
                            </svg>
                            <svg className="blogsTelegram" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="9025961_telegram_logo_icon" clip-path="url(#clip0_880_59)">
                                    <path id="Vector" d="M7.21853 11.0661L14.5931 17.5548C14.6784 17.6302 14.7818 17.6822 14.8931 17.7056C15.0045 17.729 15.1201 17.7231 15.2285 17.6883C15.3369 17.6536 15.4344 17.5912 15.5114 17.5074C15.5884 17.4236 15.6423 17.3212 15.6677 17.2102L18.7521 3.74071C18.778 3.62471 18.7719 3.50386 18.7344 3.39107C18.697 3.27828 18.6296 3.17778 18.5394 3.1003C18.4493 3.02281 18.3399 2.97125 18.2227 2.95113C18.1056 2.931 17.9852 2.94306 17.8744 2.98602L2.73142 8.93329C2.12439 9.17118 2.20642 10.0571 2.84627 10.1884L7.21853 11.0661Z" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_2" d="M7.21875 11.0661L18.3832 3.00244" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_3" d="M10.902 14.3062L8.34258 16.8655C8.25127 16.9582 8.13446 17.0217 8.00699 17.0479C7.87952 17.0741 7.74715 17.0618 7.62669 17.0125C7.50624 16.9633 7.40314 16.8793 7.3305 16.7714C7.25787 16.6634 7.21897 16.5363 7.21875 16.4062V11.0659" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_880_59">
                                        <rect width="21" height="21" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className="latest_post">
                            <div>
                                <h1 className="Latestpost">Latest post</h1>
                                <div class="container text-center">
                                    <div class="row">
                                        <div class="col-6 col-sm-2">
                                            <img className="bloglatestpostimage1" alt="" src={BlogImages.bloglatestpostimage1} />
                                        </div>
                                        <div class="col-6 col-sm-2">
                                            <h2 className="Right_collaboration_to_save_earth">Right collaboration to save<br /> earth</h2>
                                            <p className="Right_collaboration_to_save_earth_para">JAN 25 2022</p>
                                        </div>

                                        <div class="w-100"></div>

                                        <div class="col-6 col-sm-3">
                                            <img className="bloglatestpostimage2" alt="" src={BlogImages.bloglatestpostimage2} />
                                        </div>

                                        <div class="col-6 col-sm-3">
                                            <h2 className="Powering_the_Energy_Generation">Powering the Energy Generation</h2>
                                            <p className="Powering_the_Energy_Generation_para">JAN 25 2022</p>
                                        </div>

                                        <div class="w-100"></div>

                                        <div class="col-6 col-sm-3">
                                            <img className="bloglatestpostimage3" alt="" src={BlogImages.bloglatestpostimage3} />
                                        </div>

                                        <div class="col-6 col-sm-3">
                                            <h2 className="How_efficient_is_eco_system_world">How efficient is eco system<br /> world</h2>
                                            <p className="How_efficient_is_eco_system_world_para">JAN 25 2022</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <img className="bloglatestpostimage1" alt="" src="./Teraone/bloglatestpostimage2.png" />
                    <img className="bloglatestpostimage1" alt="" src="./Teraone/bloglatestpostimage3.png" />
                     */}
                        </div>

                        <div className="browse_by_category">
                            <h1 className="browsebycategory">browse by category</h1>
                            <div class="container ">
                                <div class="row">
                                    <div class="col-6 col-sm-10">
                                        <div className="Eco_System">
                                            <ul><li>Eco System</li></ul>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-2">
                                        <p className="para">(7)</p>
                                    </div>

                                    <div class="w-100"></div>

                                    <div class="col-6 col-sm-10">
                                        <div className="Eco_System">
                                            <ul><li>Energy Generation</li></ul>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-2">
                                        <p className="para">(9)</p>
                                    </div>
                                    <div class="w-100"></div>

                                    <div class="col-6 col-sm-10">
                                        <div className="Eco_System">
                                            <ul><li>Solar Energy</li></ul>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-2">
                                        <p className="para">(7)</p>
                                    </div>
                                    <div class="w-100"></div>

                                    <div class="col-6 col-sm-10">
                                        <div className="Eco_System">
                                            <ul><li>Water turbines</li></ul>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-2">
                                        <p className="para">(9)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

