import React from 'react';
import { Link } from 'react-router-dom';


export default function Navbar() {
    return (
        <header className="container">
        
        
            <div className="social-container">
                <a  href="https://www.whatsapp.com/">
                <svg className="whatsapp" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="843786_whatsapp_icon">
                        <g id="Group">
                            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M17.1799 3.96863C15.457 2.24378 13.1659 1.29348 10.7248 1.29248C5.69509 1.29248 1.60144 5.3858 1.59948 10.4171C1.59877 12.0254 2.01897 13.5952 2.81755 14.9791L1.52295 19.7077L6.3604 18.4387C7.69323 19.1657 9.1939 19.5489 10.7212 19.5494H10.7249C10.7246 19.5494 10.7252 19.5494 10.7249 19.5494C15.754 19.5494 19.848 15.4557 19.8501 10.4243C19.851 7.98605 18.9027 5.69339 17.1799 3.96863ZM10.7248 18.0083H10.7218C9.36084 18.0078 8.02597 17.6421 6.86146 16.9511L6.58454 16.7868L3.71392 17.5397L4.48017 14.741L4.29981 14.454C3.54061 13.2464 3.13963 11.8507 3.14022 10.4176C3.14185 6.23579 6.54435 2.83367 10.7279 2.83367C12.7537 2.8343 14.6581 3.62429 16.09 5.05791C17.522 6.49153 18.3102 8.39714 18.3094 10.4237C18.3077 14.6058 14.9053 18.0083 10.7248 18.0083Z" fill="#174689" />
                            <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M14.8852 12.328C14.6572 12.2138 13.5362 11.6623 13.3272 11.5862C13.1182 11.5101 12.9662 11.472 12.8142 11.7003C12.6622 11.9285 12.2252 12.4421 12.0922 12.5943C11.9592 12.7464 11.8262 12.7656 11.5982 12.6513C11.3702 12.5372 10.6355 12.2965 9.76466 11.5197C9.0868 10.915 8.62923 10.1684 8.49617 9.9401C8.36319 9.71189 8.48202 9.58851 8.59618 9.47479C8.69875 9.37259 8.82421 9.2085 8.93823 9.07533C9.0522 8.94224 9.09021 8.84704 9.16622 8.69499C9.24223 8.54278 9.20426 8.40962 9.14722 8.29553C9.09021 8.18141 8.63423 7.05912 8.44424 6.60251C8.25911 6.15797 8.07116 6.2182 7.93118 6.21113C7.79838 6.2045 7.64618 6.20312 7.4942 6.20312C7.34221 6.20312 7.09515 6.26021 6.8862 6.48842C6.67721 6.71671 6.08813 7.26837 6.08813 8.39054C6.08813 9.51287 6.90516 10.5971 7.01917 10.7492C7.13315 10.9014 8.62697 13.2044 10.9142 14.192C11.4582 14.427 11.8829 14.5673 12.214 14.6723C12.7603 14.8459 13.2573 14.8214 13.6502 14.7627C14.0883 14.6972 14.9992 14.2111 15.1892 13.6786C15.3792 13.1459 15.3792 12.6893 15.3222 12.5943C15.2652 12.4992 15.1132 12.4421 14.8852 12.328Z" fill="#174689" />
                        </g>
                    </g>
                </svg></a>
                <a href="https://facebook.com/">
                <svg className="facebook" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="104498_facebook_icon">
                        <path id="Vector" d="M14.976 8.05248H12.1442V6.19521C12.1442 5.49772 12.6064 5.3351 12.932 5.3351C13.2569 5.3351 14.9304 5.3351 14.9304 5.3351V2.2688L12.1782 2.25806C9.12304 2.25806 8.42777 4.54501 8.42777 6.00852V8.05248H6.66089V11.2121H8.42777C8.42777 15.2671 8.42777 20.1529 8.42777 20.1529H12.1442C12.1442 20.1529 12.1442 15.2189 12.1442 11.2121H14.6519L14.976 8.05248Z" fill="#174689" />
                    </g>
                </svg>
                </a>
                {/* <img className="facebook" alt="" src="Teraone/104498_facebook_icon.svg" /> */}
                <a href="https://linkedin.com/">
                <svg className="linkedin" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="367593_linkedin_business_network_social_icon">
                        <path id="Vector" d="M2.10517 7.62283H5.79703V18.7328H2.10517V7.62283ZM3.97581 2.26733C2.71224 2.26733 1.88672 3.09688 1.88672 4.18568C1.88672 5.25208 2.68808 6.10574 3.92749 6.10574H3.95104C5.23876 6.10574 6.0407 5.25204 6.0407 4.18568C6.01654 3.09688 5.2388 2.26733 3.97581 2.26733ZM14.8616 7.36184C12.9019 7.36184 12.0239 8.43973 11.5342 9.19565V7.62283H7.8412C7.89009 8.66507 7.8412 18.7328 7.8412 18.7328H11.5342V12.5282C11.5342 12.1959 11.5583 11.8648 11.6555 11.6268C11.9229 10.9634 12.5305 10.2764 13.5497 10.2764C14.8869 10.2764 15.4209 11.2957 15.4209 12.7885V18.7328H19.1133V12.3621C19.1133 8.94964 17.2921 7.36184 14.8616 7.36184Z" fill="#174689" />
                    </g>
                </svg>
                </a>
                {/* <img className="linkedin" alt="" src="Teraone/367593_linkedin_business_network_social_icon.svg" /> */}
                <a href="https://telegram.com/">
                <svg className="telegram" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="9025961_telegram_logo_icon" clip-path="url(#clip0_200_51)">
                        <path id="Vector" d="M7.21878 11.0661L14.5934 17.5548C14.6786 17.6302 14.782 17.6822 14.8934 17.7056C15.0048 17.729 15.1203 17.7231 15.2287 17.6883C15.3371 17.6536 15.4346 17.5912 15.5116 17.5074C15.5886 17.4236 15.6425 17.3212 15.668 17.2102L18.7524 3.74071C18.7782 3.62471 18.7721 3.50386 18.7347 3.39107C18.6972 3.27828 18.6298 3.17778 18.5397 3.1003C18.4496 3.02281 18.3401 2.97125 18.223 2.95113C18.1058 2.931 17.9854 2.94306 17.8746 2.98602L2.73167 8.93329C2.12463 9.17118 2.20667 10.0571 2.84651 10.1884L7.21878 11.0661Z" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path id="Vector_2" d="M7.21875 11.0661L18.3832 3.00244" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path id="Vector_3" d="M10.902 14.3062L8.34258 16.8655C8.25127 16.9582 8.13446 17.0217 8.00699 17.0479C7.87952 17.0741 7.74715 17.0618 7.62669 17.0125C7.50624 16.9633 7.40314 16.8793 7.3305 16.7714C7.25787 16.6634 7.21897 16.5363 7.21875 16.4062V11.0659" stroke="#174689" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_200_51">
                            <rect width="21" height="21" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                </a>
                {/* <img className="telegram" alt="" src="Teraone/9025961_telegram_logo_icon.svg" /> */}

            </div>
            <div className="element-phone-icon">

                <img className="img" alt="" src="Teraone/352067_phone_icon.png" />
                <p className="phone-text-wrapper" >347-470-1812</p>

            </div>
            <div className="element-email-letter">
                <img className="icons" alt="" src="Teraone/1564504_email_letter_mail_message_icon 1.png" />
                <p className="email-text-wrapper" >info@terraone.engineering</p>

            </div>


            {/* <div className="topnav" id="myTopnav">
            <img src="Teraone/Final logo PNG 2.png" alt="Logo" width="30" height="24" className="logo" />
                
                <a className="nav-link" aria-current="page" href="/HOME">HOME</a> 
                    <a className="nav-link" aria-current="page" href="/">ABOUT</a>                            
                    <a className="nav-link" href="/">SERVICES</a>
                    <a className="nav-link" href="/">CONTACT</a>
                    <a className="nav-link" href="/">PORTFOLIO</a>
                    <a className="nav-link" href="/">BLOGS</a>
                    <button className="button" type="submit">GET FREE CONSULTATION</button>
                        
                    <form className="d-flex" role="search">
                           </form>                      
                                                        
                <a href="javascript:void(0);" className="icon" onclick="myFunction()">
                    <i className="fa fa-bars"></i>
                </a>
            </div> */}

            <nav className="navbar navbar-expand-lg ">
                <div>
                    <img src="Teraone/Final logo PNG 2.png" alt="Logo" width="30" height="24" className="logo" />
                </div>
                <div className="container-fluid" id='navbarContainer'>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Home">HOME</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/About">ABOUT</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Services">SERVICES</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Contact">CONTACT</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Portfolio">PORTFOLIO</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Blogs">BLOGS</Link>
                            </li>
                        </ul>

                        <form className="d-flex" role="search">
                            <button className="button" type="submit">GET FREE CONSULTATION</button>
                        </form>
                    </div>
                </div>
            </nav>
        </header>

    )
}
